import { Component, Input, booleanAttribute } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
	imports: [MatProgressSpinnerModule],
	selector: 'jp-spinner',
	standalone: true,
	templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
	@Input({ transform: booleanAttribute }) public fullscreen: boolean | undefined;
	@Input() public message: string | undefined;
	@Input({ transform: booleanAttribute }) public statusSuccess: boolean | undefined;
	@Input({ transform: booleanAttribute }) public statusFail: boolean | undefined;
}

<mat-dialog-content class="jp-file-view__content">
	<p class="usa-prose font-sans-sm text-base-darkest">
		<!--Top right close button-->
		<!--
		<button
			aria-label="Close file viewer"
			class="jp-file-view__x usa-button usa-button--unstyled float-right margin-left-105"
			mat-button
			[mat-dialog-close]="true"
		>
			<svg aria-hidden="true" class="usa-icon" focusable="false" role="img">
				<use xlink:href="/uswds/img/sprite.svg#cancel"></use>
			</svg>
		</button>
		-->
		<!--File name-->
		<b>{{props.fileDescription}}</b>
	</p>
	<hr class="bg-base-darkest margin-top-0 width-full">
	<!--File viewer-->
	@if (props.s3Url$ | async; as s3Url) {
		@if (isOfficeDocument) {
			<jp-spinner id="spinner" class="margin-top-1"></jp-spinner>
			<ngx-doc-viewer
				class="height-full width-full"
				tabindex="0"
				viewer="office"
				disableContent="popout-hide"
				[url]="s3Url"
				(loaded)="contentLoaded()"
			></ngx-doc-viewer>
		} @else if (isOtherPreview) {
			<jp-spinner id="spinner" class="margin-top-1"></jp-spinner>
			<ngx-doc-viewer
				class="height-full width-full"
				tabindex="0"
				viewer="url"
				[url]="s3Url"
				(loaded)="contentLoaded()"
			></ngx-doc-viewer>
		} @else {
			<p class="usa-prose font-sans-sm text-base-darkest">
				<a [id]="s3ObjKey" [download]="s3ObjKey"></a>
				Unable to preview. Please
				<button 
					class="usa-button--unstyled" 
					type="button" 
					(click)="openUrl()"
					style="cursor: pointer;"
					>download file</button
				> to view.
			</p>
		}
	} @else {
		<jp-spinner class="margin-top-1"></jp-spinner>
	}
</mat-dialog-content>

<mat-dialog-actions align="end" class="bg-white">
	<div class="padding-05">
		<button 
			mat-button
			class="usa-button usa-button--outline"
			type="button"
			[mat-dialog-close]="true"
			>Close</button>
	</div>
</mat-dialog-actions>

import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { JPFile } from '../../types/jp-file.type';

@Component({
	imports: [AsyncPipe, DatePipe, MatDialogModule, NgxDocViewerModule],
	selector: 'jp-file-details',
	standalone: true,
	templateUrl: 'file-details.component.html'
})
export class FileDetailsComponent {
	constructor( @Inject(MAT_DIALOG_DATA) public props: JPFile ) { }
}

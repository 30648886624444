import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileCardComponent } from '../../components/file-card/file-card.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { HttpService } from '../../services/http.service';
import { UserService } from '../../services/user.service';
import { JPFile, sortJPFile } from '../../types/jp-file.type';
import { UserRole } from '../../types/user-role.type';

interface Column {
	name: string;
	files: JPFile[];
}

@Component({
	imports: [AsyncPipe, FileCardComponent, SpinnerComponent],
	selector: 'jp-library',
	standalone: true,
	templateUrl: './library.component.html'
})
export class LibraryComponent {
	public columns: Column[] = [];
	public columnsReady$: Observable<boolean>; // Indicates columns ready to render
	public publisher$: Observable<boolean>; // Indicates publisher visibility

	userInfo: UserRole[] | null = null;

	constructor( private _http: HttpService, private _userService: UserService ) { 
		this.columnsReady$ = this.getColumns();
		this._userService.userInfo.subscribe(result => this.userInfo = result );
		this.publisher$ = this._userService.userInfo.pipe(map(result => {
			if (result?.includes('Publisher')) { return true; }
			return false;
		}));
	}

	private getColumns(): Observable<boolean> {
		/**
		 * Get files from S3 via Portal API.
		 */
		return this._http.getHttp('/jportal-files').pipe(map(response => {
			if (response.status === 200 && response.body.success === true) {
				let files = response.body.payload as JPFile[];

				// Sort files by descending creation date
				files = files
					.filter(file => file.fileVersion === 0) // Only get latest version
					.sort((a, b) => sortJPFile(b.lastUpdateAt, a.lastUpdateAt));

				// Map files to the columns they appear in
				this.columns = this.mapColumns(files);

			} else {
				console.error(response);
			}
			return true;
		}));
	}

	private mapColumns(files: JPFile[]): Column[] {
		/**
		 * Map files to columns.
		 * Return array of records, each record with column.name and column.files.
		 */
		// First, map columns in dictionary format
		let columnsRecord: Record<string, JPFile[]> = {};
		for (let file of files) {
			for (let fileColumn of file.fileVisibleIn) {
				if (!columnsRecord[fileColumn]) { columnsRecord[fileColumn] = []; }
				columnsRecord[fileColumn].push(file);
			}
		}
		// Then, transform into array for rendering
		return this.columnsRecordToArray(columnsRecord);
	}

	private columnsRecordToArray(columnsRecord: Record<string, JPFile[]>): Column[] {
		/**
		 * Array-ify columns record.
		 */
		let columnsArray: Column[] = []
		for (let columnName in columnsRecord) {
			columnsArray.push({
				name: columnName,
				files: columnsRecord[columnName]
			});
		}
		// Sort columns alphabetically
		return columnsArray.sort((a, b) => a.name.localeCompare(b.name));
	}
}

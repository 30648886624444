<jp-banner></jp-banner>
<jp-nav></jp-nav>

<main class="main-content" id="main-content">
	@if (authGuard.loader$ | async) {
		<jp-spinner [fullscreen]="true" [message]="'Authorizing...'"></jp-spinner>
	}
	<router-outlet></router-outlet>
</main>

<jp-footer></jp-footer>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	apiRoot = environment.apiEndpoint;

	constructor( private _http: HttpClient, private _authService: AuthService ) { }

	// Portal API methods, with authentication:

	public getHttp(url: string, params?: any): Observable<HttpResponse<any>> {
		return this._http
			.get<any>(this.apiRoot + url, this.buildHeaders(params))
			.pipe(retry(2), catchError(this.handleError('getHttp')));
	}

	public postHttp(url: string, payload: any, params?: any): Observable<HttpResponse<any>> {
		return this._http
			.post<any>(this.apiRoot + url, payload, this.buildHeaders(params))
			.pipe(retry(2), catchError(this.handleError('postHttp')));
	}

	public putHttp(url: string, payload: any, params?: any): Observable<HttpResponse<any>> {
		return this._http
			.put<any>(this.apiRoot + url, payload, this.buildHeaders(params))
			.pipe(retry(2), catchError(this.handleError('putHttp')));
	}

	public deleteHttp(url: string, uid: string, params?: any): Observable<HttpResponse<any>> {
		return this._http
			.delete<any>(`${this.apiRoot}${url}/${uid}` , this.buildHeaders(params))
			.pipe(retry(2), catchError(this.handleError('deleteHttp')));
	}

	private buildHeaders(params?: any) {
        let httpParams = new HttpParams();
        if (params) { for (const key in params) { httpParams = httpParams.append(key, params[key]); }}
		const options: object = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'OktaToken': `${this._authService.getAuthToken()}`
			}),
			observe: 'response',
            params: httpParams
		};
		return options;
	}

	// Generic methods (e.g. for presigned URLs), no authentication:

	public putHttpPublic(url: string, payload: any, headers?: any, params?: any): Observable<HttpResponse<any>> {
		let httpHeaders = new HttpHeaders();
		if (headers) { for (const key in headers) { httpHeaders = httpHeaders.append(key, headers[key]); }}
		let httpParams = new HttpParams();
        if (params) { for (const key in params) { httpParams = httpParams.append(key, params[key]); }}
		const options: object = {
			headers: httpHeaders,
			observe: 'response',
            params: httpParams
		};
		return this._http
			.put<any>(url, payload, options)
			.pipe(retry(2), catchError(this.handleError('putHttp')));
	}

	// Error handling

	private handleError(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			console.log(`Error while calling: ${operation}`)
			console.error(error);
			return of(result as any);
		};
	}

}

import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideOAuthClient } from 'angular-oauth2-oidc';

import { routes } from './app.routes';
import { AuthService } from './services/auth.service';

export const rootConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }), 
		provideRouter(routes),
		provideHttpClient(),
		provideAnimations(),
		provideOAuthClient(),
		{
			provide: APP_INITIALIZER,
			useFactory: initAuth,
			multi: true,
			deps: [AuthService]
		}
	]
};

export function initAuth(_authService: AuthService) { return () => _authService.login(); }

<mat-dialog-content class="jp-file-details__content">
	<p class="usa-prose font-sans-sm text-base-darkest">
		<b>{{props.fileDescription}}</b>
	</p>
	<hr class="bg-base-darkest margin-top-0 width-full">

	<!--File details-->
	<p class="usa-prose font-sans-xs margin-bottom-0 text-base-darkest">
		<b>File: </b> {{props.s3ObjKey}}
	</p>
	<p class="usa-prose font-sans-xs margin-bottom-0 text-base-darkest">
		<b>Last update by: </b> {{props.lastUpdateBy}}
	</p>
	<p class="usa-prose font-sans-xs margin-bottom-0 text-base-darkest">
		<b>Last update at: </b> {{props.lastUpdateAt | date:'medium'}}
	</p>
	<p class="usa-prose font-sans-xs margin-bottom-0 text-base-darkest">
		<b>Created by: </b> {{props.fileCreatedBy}}
	</p>
	<p class="usa-prose font-sans-xs margin-bottom-0 text-base-darkest">
		<b>Created at: </b> {{props.fileCreatedAt | date:'medium'}}
	</p>
	<p class="usa-prose font-sans-xs margin-bottom-0 text-base-darkest">
		<b>Categories: </b>
	</p>
	<ul class="usa-list margin-left-2">
		@for (column of props.fileVisibleIn; track column) {
			<li>{{column}}</li>
		}
	</ul>
</mat-dialog-content>

<mat-dialog-actions align="end" class="bg-white">
	<div class="padding-05">
		<button 
			mat-button
			class="usa-button usa-button--outline"
			[mat-dialog-close]="true"
			>Close</button>
	</div>
</mat-dialog-actions>

<div class="usa-section grid-container padding-bottom-6 padding-top-2 desktop:padding-top-4">

	<div class="grid-row margin-bottom-3 desktop:margin-bottom-2">
		<!--Information-->
		<div class="desktop:grid-col-7">
			<h1 class="font-heading-lg">
				Welcome to the National Law Enforcement Accountability Database (NLEAD) Information Library
			</h1>
			<p class="usa-prose font-sans-xs margin-y-05 padding-bottom-2">
				The Information Library is a repository of guidance, policy, templates, technical 
				resources, and training that exists to help agencies successfully implement NLEAD and 
				comply with the directives in Section 5 of 
				<a 
					class="usa-link"
					href="https://www.govinfo.gov/content/pkg/FR-2022-05-31/pdf/2022-11810.pdf"
					>Executive Order 14074: Advancing Effective, Accountable Policing and Criminal Justice 
					Practices to Enhance Public Trust and Public Safety</a
				>.
			</p>
			<p class="usa-prose font-sans-xs margin-y-05 padding-bottom-2">
				To learn about NLEAD, please visit 
				<a 
					class="usa-link"
					href="https://nlead.gov/"
					>NLEAD.gov</a
				>, which contains publicly available background and support information, such as the PIA,
				SORN, and Press Releases. To understand how NLEAD is implemented in your agency, contact 
				your Agency NLEAD Liaison Officer. After your Agency&#8217;s User Account Manager grants access,
				click 
				<a 
					class="usa-link"
					href="https://nlead.usdoj.gov/"
					>Access NLEAD</a
				>.
			</p>
		</div>
		<!--Help and Support-->
		<div class="desktop:grid-col-5 desktop:margin-top-2">
			<div class="bg-base-lightest border-2px border-base-light padding-2 radius-lg desktop:margin-left-205">
				<h2 class="font-sans-sm margin-top-0">Help and Support</h2>
				<p class="usa-prose font-sans-3xs margin-y-05">
					For questions about policy, governance, and training support, including suggestions for
					new features and document changes, contact the NLEAD Project Management Office.
				</p>
				<p class="usa-prose font-sans-3xs margin-bottom-1 margin-top-05">
					<a 
						class="usa-link"
						href="mailto:NLEAD.PMO@usdoj.gov"
						>NLEAD.PMO&#64;USDOJ.GOV</a
				></p>
				<p class="usa-prose font-sans-3xs margin-y-05">
					For technical support with the NLEAD application, contact the NLEAD Technical Service 
					Provider.
				</p>
				<p class="usa-prose font-sans-3xs margin-bottom-0 margin-top-05">
					<a 
						class="usa-link"
						href="mailto:NLEAD.TECHNICAL.SUPPORT@usdoj.gov"
						>NLEAD.TECHNICAL.SUPPORT&#64;USDOJ.GOV</a
				></p>
			</div>
			<!--Manage Files-->
			@if (publisher$ | async) {
				<div class="desktop:margin-left-205">
					<a 
						class="usa-button margin-top-2 width-full" 
						href="/manage"
						>Manage Files</a
					>
				</div>
			}
		</div>
	</div>

	<!--File columns-->
	<div class="grid-row margin-bottom-1">
	@if (columnsReady$ | async) {
		<div class="usa-accordion usa-accordion--multiselectable" data-allow-multiple>

		@for (column of columns; track column.name) {
			<button
				type="button"
				class="usa-accordion__button margin-bottom-05"
				aria-expanded="true"
				[attr.aria-controls]="column.name"
			>
				<h2 class="font-sans-sm margin-0">{{column.name}}</h2>
			</button>

			<div [id]="column.name" class="usa-accordion__content margin-bottom-1 overflow-x-hidden padding-x-0">
				<ul class="usa-card-group">
					@for (file of column.files; track file.s3ObjKey) {
						<li class="usa-card usa-card--flag tablet:grid-col-6 desktop:grid-col-4">
						<jp-file-card
							[fileDescription]="file.fileDescription"
							[lastUpdateAt]="file.lastUpdateAt"
							[s3ObjKey]="file.s3ObjKey"
						></jp-file-card>
						</li>
					}
				</ul>
			</div>
		}
		</div>
	} @else {
		<jp-spinner class="width-full"></jp-spinner>
	}
	</div>

</div>

import { AsyncPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Sort, MatSortModule } from '@angular/material/sort';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileDetailsComponent } from '../../components/file-details/file-details.component';
import { FileViewComponent } from '../../components/file-view/file-view.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { UploadComponent } from '../../components/upload/upload.component';
import { HttpService } from '../../services/http.service';
import { JPFile, sortJPFile } from '../../types/jp-file.type';

@Component({
	imports: [AsyncPipe, DatePipe, MatMenuModule, MatPaginatorModule, MatSortModule, SpinnerComponent],
	selector: 'jp-manage',
	standalone: true,
	templateUrl: './manage.component.html'
})
export class ManageComponent {
	public files: JPFile[] = [];
	public filesReady$: Observable<boolean>; // Indicates file list ready to render
	public filesSlice: JPFile[] = []; // Files visible on current table page

	public pageSizes: number[];
	public pageEvent: PageEvent;

	constructor( private _http: HttpService, private _modal: MatDialog ) {
		// Set pagination options
		this.pageSizes = [10, 25, 50];

		// Set initial pagination (page # and size)
		this.pageEvent = new PageEvent();
		this.pageEvent.pageIndex = 0;
		this.pageEvent.pageSize = this.pageSizes[0];

		// Populate files
		this.filesReady$ = this.getFiles(); 
	}

	private getFiles(): Observable<boolean> {
		/**
		 * Get files from S3 via Portal API. By default, sort by descending last update.
		 */
		return this._http.getHttp('/jportal-files').pipe(map(response => {
			if (response.status === 200 && response.body.success === true) {
				this.files = response.body.payload as JPFile[];
				this.files = this.files
					.filter(file => file.fileVersion === 0) // Only get latest version
					.sort((a, b) => a.lastUpdateAt.localeCompare(b.lastUpdateAt))
					.reverse();
				this.setFilesSlice();
			} else {
				console.error(response);
			}
			return true;
		}));
	}

	private getPresignedUrl(s3ObjKey: string): Observable<string> {
		/**
		 * Generate presigned S3 URL for a file via Portal API.
		 */
		let params = Object.assign({ "s3-obj-key": s3ObjKey });
		return this._http.getHttp('/jportal-file-link', params).pipe(map(response => {
			let fileUrl = "_blank";
			if (response.status === 200 && response.body.success === true) {
				fileUrl = response.body.payload;
			} else {
				console.error(response);
			}
			return fileUrl;
		}));
	}

	private setFilesSlice() {
		/**
		 * Get slice of files based on table pagination.
		 */
		this.filesSlice = this.files.slice(
			this.pageEvent.pageIndex * this.pageEvent.pageSize, 
			(this.pageEvent.pageIndex + 1) * this.pageEvent.pageSize
		);
	}

	public handlePageEvent(e: PageEvent) {
		/**
		 * Handle table pagination.
		 */
		this.pageEvent = e;
		this.setFilesSlice();
	}

	public openFileView(fileDescription: string, s3ObjKey: string) {
		/**
		 * Open modal to view one file.
		 */
		this._modal.open(FileViewComponent, {
			autoFocus: false,
			data: {
				fileDescription: fileDescription,
				s3ObjKey: s3ObjKey,
				s3Url$: this.getPresignedUrl(s3ObjKey)
			},
			height: 'fit-content',
			width: '100vh',
			panelClass: 'jp__mat-mdc-dialog-container',
			restoreFocus: false
		});
	}

	public openFileDetails(file: JPFile) {
		/**
		 * Open modal to view one file's details.
		 */
		this._modal.open(FileDetailsComponent, {
			autoFocus: false,
			data: file,
			height: 'fit-content',
			width: '100vh',
			panelClass: 'jp__mat-mdc-dialog-container',
			restoreFocus: false
		});
	}

	public sortData(sort: Sort) {
		/**
		 * Handle table column sort.
		 */
		switch (sort.active) {
			case "file":
				this.files.sort((a, b) => sortJPFile(a.s3ObjKey, b.s3ObjKey)); break;
			case "name":
				this.files.sort((a, b) => sortJPFile(a.fileDescription, b.fileDescription)); break;
			case "lastUpdateBy":
				this.files.sort((a, b) => sortJPFile(a.lastUpdateBy, b.lastUpdateBy)); break;
			case "lastUpdateAt":
				this.files.sort((a, b) => sortJPFile(a.lastUpdateAt, b.lastUpdateAt)); break;
		}
		if (sort.direction === "desc") {
			this.files.reverse();
		}
		this.setFilesSlice();
	}

	public async openUploadDialog(): Promise<any> {
		/**
		 * Open file upload dialog.
		 */
		const uploadModal = this._modal.open(UploadComponent, {
			autoFocus: false,
			data: {},
			height: 'fit-content',
			width: '100vh',
			panelClass: 'jp__mat-mdc-dialog-container',
			restoreFocus: false
		});

		return lastValueFrom(uploadModal.afterClosed())
			.then(result => { 
				if (result.status === 200) { window.location.reload(); }
				return Promise.resolve(result);
			})
	}

	public openUrl(s3ObjKey: string) {
		/**
		 * Download file using presigned S3 URL from the Portal API.
		 */
		let url = document.getElementById(s3ObjKey) as HTMLAnchorElement;
		this.getPresignedUrl(s3ObjKey).subscribe(href => {
			url.href = href;
			url.click();
		});
	}
}

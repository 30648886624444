import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthService {

	constructor( private _oauthService: OAuthService ) { }

	login(): Observable<any> {
		return new Observable((subscriber) => {
			this._oauthService.configure(environment.authConfig);
			this._oauthService.setupAutomaticSilentRefresh();
			this._oauthService.loadDiscoveryDocumentAndLogin().then((event: boolean) => {
				subscriber.complete();
			});
		});
	}

	logout() {
		this._oauthService.revokeTokenAndLogout();
	}

	getAuthToken(): string | null {
		return window.sessionStorage.getItem('id_token') ?? '';
	}

	getName(): string | null {
		let claims = this._oauthService.getIdentityClaims();
   		return claims?.name;
	}
}

import { Routes } from '@angular/router';
import { AuthenticateComponent } from './features/authenticate/authenticate.component';
import { LibraryComponent } from './features/library/library.component';
import { ManageComponent } from './features/manage/manage.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
	{ path: '', redirectTo: '/authenticate', pathMatch: 'full' },
	{ path: 'authenticate', component: AuthenticateComponent },
	{ path: 'home', component: LibraryComponent, canActivate: [AuthGuard] },
	{ path: 'manage', component: ManageComponent, canActivate: [AuthGuard] },
	{ path: '**', redirectTo: '/authenticate' }
];

import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileViewComponent } from '../file-view/file-view.component';
import { HttpService } from '../../services/http.service';

@Component({
	imports: [AsyncPipe, DatePipe, MatMenuModule],
	selector: 'jp-file-card',
	standalone: true,
	templateUrl: './file-card.component.html'
})
export class FileCardComponent {
	@Input({required: true}) public fileDescription!: string;
	@Input({required: true}) public lastUpdateAt!: string;
	@Input({required: true}) public s3ObjKey!: string;

	constructor( private _http: HttpService, private _modal: MatDialog ) { }

	private getPresignedUrl(): Observable<string> {
		/**
		 * Generate presigned S3 URL for a file via Portal API.
		 */
		let params = Object.assign({ "s3-obj-key": this.s3ObjKey });
		return this._http.getHttp('/jportal-file-link', params).pipe(map(response => {
			let fileUrl = "_blank";
			if (response.status === 200 && response.body.success === true) {
				fileUrl = response.body.payload;
			} else {
				console.error(response);
			}
			return fileUrl;
		}));
	}

	public openFileView() {
		/**
		 * Open modal to view one file.
		 */
		this._modal.open(FileViewComponent, {
			autoFocus: false,
			data: {
				fileDescription: this.fileDescription,
				s3ObjKey: this.s3ObjKey,
				s3Url$: this.getPresignedUrl()
			},
			height: 'fit-content',
			width: '100vh',
			panelClass: 'jp__mat-mdc-dialog-container',
			restoreFocus: true
		});
		// modalRef.afterClosed().subscribe(result => {
		// 	console.log(`Modal result: ${result}`);
		// });
	}

	public openUrl() {
		/**
		 * Download file using presigned S3 URL from the Portal API.
		 */
		let url = document.getElementById(this.s3ObjKey) as HTMLAnchorElement;
		this.getPresignedUrl().subscribe(href => {
			url.href = href;
			url.click();
		});
	}
}

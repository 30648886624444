import { Component } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'jp-nav',
	standalone: true,
	templateUrl: './nav.component.html'
})
export class NavComponent {
	public name: string; // User's name, to display on nav bar

	constructor( private _authService: AuthService ) {
		let id = this._authService.getName();
		this.name = id ? id.split(", ").reverse().join(" ") : "";
	}

	public logout() { this._authService.logout(); }
}

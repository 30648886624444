@if (progress.form) {
	<mat-dialog-content class="jp-upload__content">
		<p class="usa-prose font-sans-sm text-base-darkest">
			<b>Upload Document</b>
		</p>
		<hr class="bg-base-darkest width-full">
		@if (progress.noFile) {
			<p class="usa-prose font-sans-xs margin-bottom-05 text-red">Please select a file.</p>
		}
		<form id="upload-form" class="usa-form maxw-full padding-05 text-base-darkest">
			<!--Choose file-->
			<input
				id="file-input"
				class="usa-file-input"
				type="file"
				name="file-input"
				required
			/>
			<!--Input file name-->
			<label class="usa-label" for="description">Name</label
				><input
					class="usa-input"
					id="description"
					name="description"
			>
			<!--Select file categories-->
			<div class="usa-accordion">
				<button
					class="usa-accordion__button margin-top-4"
					aria-expanded="true"
					aria-controls="column-options"
					type="button"
				>
					<label class="usa-label margin-top-0">Categories</label>
				</button>
				<div id="column-options" class="usa-accordion__content">
					@for (column of columns; track column) {
						<div class="usa-checkbox">
							<input
								checked
								class="usa-checkbox__input"
								[id]="column"
								type="checkbox"
								name="column"
								[value]="column"
							/>
							<label class="usa-checkbox__label" [for]="column"
								>{{column}}</label
							>
						</div>
					}
				</div>
			</div>
		</form>
	</mat-dialog-content>

	<mat-dialog-actions align="end" class="bg-white">
		<div class="padding-05">
			<button 
				mat-button
				class="usa-button usa-button--outline"
				type="reset"
				(click)="close()"
				>Cancel</button>
			<button
				class="usa-button margin-right-0"
				type="submit"
				(click)="submit()"
				>Submit</button>
		</div>
	</mat-dialog-actions>
}

@if (progress.load) {
	<mat-dialog-content class="jp-upload__content">
		<div class="height-3 width-x-auto"></div>
		<jp-spinner [message]="'Uploading file, please wait...'"></jp-spinner>
	</mat-dialog-content>

	<mat-dialog-actions align="end" class="bg-white"></mat-dialog-actions>
}

@if (progress.success) {
	<mat-dialog-content class="jp-upload__content">
		<div class="height-6 width-x-auto"></div>
		<jp-spinner [message]="'Upload succeeded!'" [statusSuccess]="true"></jp-spinner>
	</mat-dialog-content>

	<mat-dialog-actions align="end" class="bg-white">
		<div class="padding-05">
			<button 
				mat-button
				class="usa-button usa-button--outline"
				type="reset"
				(click)="close()"
				>Close</button>
		</div>
	</mat-dialog-actions>
}

@if (progress.fail) {
	<mat-dialog-content class="jp-upload__content">
		<div class="height-6 width-x-auto"></div>
		<jp-spinner [message]="'Unable to upload.'" [statusFail]="true"></jp-spinner>
	</mat-dialog-content>

	<mat-dialog-actions align="end" class="bg-white">
		<div class="padding-05">
			<button 
				mat-button
				class="usa-button usa-button--outline"
				type="reset"
				(click)="close()"
				>Close</button>
		</div>
	</mat-dialog-actions>
}

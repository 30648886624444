import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpService } from './http.service';
import { UserRole } from '../types/user-role.type';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	userInfo = new BehaviorSubject<UserRole[] | null>(null);

	constructor( private _http: HttpService ) { }

	initUserInfo() {
		this._http.getHttp('/jportal-user-permission').subscribe(response => {
			if (response.status === 200 && response.body.success === true) {
				response.body.payload.push('Consumer')
				this.userInfo.next(response.body.payload);
			} else {
				this.userInfo.next(['Consumer']);
			}
		});
	}
}

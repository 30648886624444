import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { columns } from '../../environments/environment';
import { HttpService } from '../../services/http.service';
import { JPFile } from '../../types/jp-file.type';

@Component({
	imports: [MatDialogModule, SpinnerComponent],
	selector: 'jp-upload',
	standalone: true,
	templateUrl: './upload.component.html'
})
export class UploadComponent {
	public columns: string[] = columns;
	public progress = {
		form: true, // Step 1: upload form
		load: false, // Step 2: load spinner while waiting for submit
		success: false, // Success status
		fail: false, // Failure status
		noFile: false // No file selected
	}

	constructor( public modal: MatDialogRef<UploadComponent>, private _http: HttpService ) { }

	public close() { this.modal.close({ status: this.progress.success ? 200 : 400 }); }

	public submit() {
		/**
		 * Upload a document via Portal API.
		 */
		this.progress.noFile = false;
		const target = document.getElementById('upload-form') as HTMLFormElement;

		let file = target['file-input'].files[0];
		if (file) {
			let s3ObjKey = file.name;
			let fileSize = file.size;
			let fileDescription = target['description'].value;
			let fileVisibleIn: string[] = []; // TODO: handle blank columns
			this.columns.forEach(column => {
				if (target[column].checked) {
					fileVisibleIn.push(column);
				}
			})

			const fileObj: JPFile = {
				"s3ObjKey": s3ObjKey,
				"fileVersion": null,
				"fileLatestVersion": null,
				"fileSize": fileSize,
				"fileDescription": (fileDescription?.length > 0) ? fileDescription : s3ObjKey,
				"fileVisibleIn": fileVisibleIn,
				"fileVisibleTo": [
					"publishers",
					"consumers"
				],
				"fileCreatedBy": null,
				"fileCreatedAt": null,
				"lastUpdateAt": new Date().toISOString(),
				"lastUpdateBy": null
			}

			console.log("Uploading this file...")
			console.log(fileObj);

			// Proceed to load spinner
			this.progress.form = false;
			this.progress.load = true;
	
			// Put file in database and generate presigned URL
			this._http.postHttp('/jportal-files', fileObj).subscribe(response => {
				if (response.status === 200 && response.body.success === true) {
					const putURL = response.body.payload;

					// Upload file via presigned URL
					this._http.putHttpPublic(putURL, file, { 'Content-Type': file.type } )
						.subscribe(response => {
							console.log(response);

							// Proceed to success or fail screen
							this.progress.load = false;
							if (response.status === 200) { this.progress.success = true; } 
							else { this.progress.fail = true; }
						});

				} else {
					console.error(response);
				}
			});

		} else {
			this.progress.noFile = true;
			console.error("No file selected.");
		}
	}
}

@if (fullscreen) {
    <div class="height-viewport padding-top-4">
        <div class="width-full">
        <div class="margin-x-auto" style="width: fit-content;">
            <div class="display-flex flex-align-center flex-column">
                @if (statusSuccess) {
                    <div class="usa-icon-list__icon text-green">
                        <svg class="usa-icon height-10 width-10" aria-hidden="true" role="img">
                            <use xlink:href="/uswds/img/sprite.svg#check_circle"></use>
                        </svg>
                    </div>
                }
                @else if (statusFail) {
                    <div class="usa-icon-list__icon text-red">
                        <svg class="usa-icon height-10 width-10" aria-hidden="true" role="img">
                            <use xlink:href="/uswds/img/sprite.svg#cancel"></use>
                        </svg>
                    </div>
                }
                @else { <mat-spinner class="jp-spinner"></mat-spinner> }
            </div>
            @if (message) {
                <h1 class="font-heading-sm margin-x-auto text-center width-full">{{message}}</h1>
            }
        </div>
        </div>
    </div>
} @else {
    <div class="width-full">
    <div class="margin-x-auto" style="width: fit-content;">
        <div class="display-flex flex-align-center flex-column">
            @if (statusSuccess) {
                <div class="usa-icon-list__icon text-green">
                    <svg class="usa-icon height-10 width-10" aria-hidden="true" role="img">
                        <use xlink:href="/uswds/img/sprite.svg#check_circle"></use>
                    </svg>
                </div>
            }
            @else if (statusFail) {
                <div class="usa-icon-list__icon text-red">
                    <svg class="usa-icon height-10 width-10" aria-hidden="true" role="img">
                        <use xlink:href="/uswds/img/sprite.svg#cancel"></use>
                    </svg>
                </div>
            }
            @else { <mat-spinner class="jp-spinner"></mat-spinner> }
        </div>
        @if (message) {
            <h1 class="font-heading-sm margin-x-auto text-center width-full">{{message}}</h1>
        }
    </div>
    </div>
}

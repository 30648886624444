<section class="usa-header bg-primary-darker">
	<div class="grid-container maxw-desktop-lg">
		<div class="grid-row">
			<!--Logo-->
			<div class="tablet:grid-col-4 desktop:grid-col-3">
				<a class="usa-identifier__logos" href="/">
					<img class="padding-bottom-1 padding-top-2" src="logos/nlead_logos/NLEAD.svg" 
					alt="National Law Enforcement Accountability Database" role="img" />
				</a>
			</div>
			<div class="tablet:grid-col-2 desktop:grid-col-3"></div>

			<!--Welcome and logout-->
			<div class="tablet:grid-col-6 desktop:grid-col-6">
				@if (name.length > 0) {
					<div class="jp-nav__controls display-flex flex-align-end height-full margin-left-auto">
						<p
							class="jp-nav__welcome usa-button bg-primary-darker font-size-xs margin-right-0 padding-x-2 text-base-lighter text-normal text-right"
							style="pointer-events: none;"
							>Welcome, {{name}}</p
						>
						<button
							class="jp-nav__logout usa-button usa-button--outline usa-button--inverse margin-2"
							type="button"
							(click)="logout()"
							>Logout</button
						>
					</div>
				}
			</div>
		</div>
	</div>
</section>

<!--Breadcrumbs-->
<nav class="usa-breadcrumb" aria-label="Navigation">
	<div class="grid-container maxw-desktop-lg">
	  <ol class="usa-breadcrumb__list">
		<li class="usa-breadcrumb__list-item">
		  <a href="/" class="usa-breadcrumb__link"><span>NLEAD Information Library</span></a>
		</li>
		<li class="usa-breadcrumb__list-item usa-current" aria-current="page">
			<span>Manage Files</span>
		</li>
		</ol>
	</div>
</nav>

<!--Manage Files-->
<div class="usa-section grid-container padding-top-2 desktop:padding-top-4">
	<div class="grid-row">
		<h1 class="font-heading-lg">
			Manage Files
		</h1>
	</div>

	@if (filesReady$ | async) {
	<!--Upload-->
	<div class="grid-row flex-column flex-align-end ">
		<button
			class="usa-button margin-x-0"
			type="button"
			(click)="openUploadDialog()"
			>Upload a Document</button>
	</div>

	<!--Files table-->
	<div class="grid-row margin-top-105 jp-table__wrapper">
		<table 
			aria-label="Files table"
			class="jp-table mat-elevation-z0"
			matSort
			matSortActive="lastUpdateAt"
			matSortDirection="desc"
			matSortDisableClear="true"
			(matSortChange)="sortData($event)"
			tabindex="0"
		>
			<!--Table headers-->
			<thead>
			<tr class="bg-base-lightest border-base border-bottom-1px">
				<th mat-sort-header="file" id="fileCol" class="jp-table__cell" style="width: 25%;">
					File
				</th>
				<th mat-sort-header="name" id="nameCol" class="jp-table__cell" style="width: 25%;">
					Name
				</th>
				<th mat-sort-header="lastUpdateBy" id="lastUpdateByCol" class="jp-table__cell" style="width: 25%;">
					Last update by
				</th>
				<th mat-sort-header="lastUpdateAt" id="lastUpdateAtCol" class="jp-table__cell" style="width: 15%;">
					Last update at
				</th>
				<th id="moreCol" class="jp-table__cell" style="width: 10%;">More</th>
			</tr>
			</thead>

			<!--Table rows-->
			<tbody>
			@for (file of filesSlice; track file.s3ObjKey) {
				<tr class="border-base border-bottom-1px">
					<td headers="fileCol" class="jp-table__cell">{{file.s3ObjKey}}</td>
					<td headers="nameCol" class="jp-table__cell">{{file.fileDescription}}</td>
					<td headers="lastUpdateByCol" class="jp-table__cell">{{file.lastUpdateBy}}</td>
					<td headers="lastUpdateAtCol" class="jp-table__cell">{{file.lastUpdateAt | date:'medium'}}</td>
					<td headers="moreCol" class="jp-table__cell display-flex flex-align-center flex-column">
						<button
							aria-label="Open file menu"
							type="button"
							class="usa-button usa-button--unstyled width-2"
							[matMenuTriggerFor]="menu"
						>
							<svg aria-hidden="true" class="usa-icon width-2" focusable="false" role="img">
								<use xlink:href="/uswds/img/sprite.svg#more_horiz"></use>
							</svg>
						</button>

						<mat-menu #menu="matMenu" class="jp-menu">
							<button 
								mat-menu-item 
								class="jp-menu__item"
								type="button"
								(click)="openFileDetails(file)">
								Details
							</button>
							<button 
								mat-menu-item 
								class="jp-menu__item"
								type="button"
								(click)="openFileView(file.fileDescription,file.s3ObjKey)">
								Preview
							</button>
							<button 
								mat-menu-item
								class="jp-menu__item"
								type="button"
								(click)="openUrl(file.s3ObjKey)">
								<a [id]="file.s3ObjKey" [download]="file.s3ObjKey"></a>
								Download
							</button>
						</mat-menu>
					</td>
				</tr>
			}
			</tbody>

		</table>
		<div class="flex-align-end width-full">
			<mat-paginator
				aria-label="Select page of files"
				class="jp-paginator"
				[length]="files.length"
				[pageSizeOptions]="pageSizes"
				(page)="handlePageEvent($event)"
			></mat-paginator>
		</div>
	</div>
	
	} @else {
		<div class="height-card-lg"><jp-spinner class="margin-top-1 width-full"></jp-spinner></div>
	}
	
</div>

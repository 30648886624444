<div class="jp-card__container usa-card__container">
	<!--File icon-->
	<svg aria-hidden="true" class="jp-card__file-icon width-5" focusable="false" role="img">
		<image class="width-5" href="uswds/img/material-icons/file_open.svg"></image>
	</svg>
	<!--File name and date-->
	<div class="overflow-hidden line-height-sans-2 width-full">
		<button
			class="usa-button usa-button--unstyled"
			type="button"
			(click)="openFileView()"
			><p class="jp-card__text"><b>{{fileDescription}}</b></p>
		</button>
		<p class="jp-card__date">
			<time 
				class="usa-collection__meta-item" 
				[dateTime]="lastUpdateAt"
				>{{lastUpdateAt | date:'medium'}}</time>
		</p>
	</div>
	<!--Menu button-->
	<div class="flex-align-start float-right width-2">
		<button
			aria-label="Open file menu"
			class="usa-button usa-button--unstyled width-2"
			type="button"
			[matMenuTriggerFor]="menu"
		>
			<svg aria-hidden="true" class="usa-icon width-2" focusable="false" role="img">
				<use xlink:href="/uswds/img/sprite.svg#more_vert"></use>
			</svg>
		</button>
		<mat-menu #menu="matMenu" class="jp-menu">
			<button mat-menu-item class="jp-menu__item" type="button" (click)="openFileView()">
				Preview
			</button>
			<button mat-menu-item class="jp-menu__item" type="button" (click)="openUrl()">
				<a [id]="s3ObjKey" [download]="s3ObjKey"></a>
				Download
			</button>
		</mat-menu>
	</div>
</div>

import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { OAuthService, OAuthEvent, OAuthErrorEvent } from 'angular-oauth2-oidc';

import { BannerComponent } from './components/banner/banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LibraryComponent } from './features/library/library.component';
import { ManageComponent } from './features/manage/manage.component';
import { AuthGuard } from './guards/auth.guard';
import { UserService } from './services/user.service';

@Component({
	imports: [
		AsyncPipe,
		BannerComponent,
		FooterComponent,
		NavComponent,
		SpinnerComponent,
		LibraryComponent,
		ManageComponent,
		RouterOutlet,
	],
	selector: 'jp-root',
	standalone: true,
	templateUrl: './app.component.html'
})
export class RootComponent {
	title = 'justice-portal-ui';

	constructor(
		public authGuard: AuthGuard,
		private _oauthService: OAuthService,
		private _userService: UserService
	) {
		this._oauthService.events.subscribe((event: OAuthEvent | OAuthErrorEvent) => {
			if (event.type === 'token_received') { this._userService.initUserInfo(); }
			if (event instanceof OAuthErrorEvent) {
				console.log('OAuthError', event);
				// TODO: this.router.navigate(['/not-authorized']);
			}
		});
	}

	ngOnInit() { this._userService.initUserInfo(); }
}

import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { Observable, map } from 'rxjs';

import { SpinnerComponent } from '../spinner/spinner.component';
import { HttpService } from '../../services/http.service';
import { JPFileViewProps } from '../../types/jp-file-view.type';

@Component({
	imports: [AsyncPipe, MatDialogModule, NgxDocViewerModule, SpinnerComponent],
	selector: 'jp-file-view',
	standalone: true,
	templateUrl: 'file-view.component.html'
})
export class FileViewComponent {
	public isOfficeDocument!: boolean;
	public isOtherPreview!: boolean;
	public s3ObjKey: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public props: JPFileViewProps,
		private _http: HttpService,
		private _sanitizer: DomSanitizer
	) {
		this.s3ObjKey = props.s3ObjKey;
		
		this.isOfficeDocument = (
			props.s3ObjKey.toUpperCase().endsWith('.DOC') ||
			props.s3ObjKey.toUpperCase().endsWith('.DOCX') ||
			props.s3ObjKey.toUpperCase().endsWith('.PPT') ||
			props.s3ObjKey.toUpperCase().endsWith('.PPTX') ||
			props.s3ObjKey.toUpperCase().endsWith('.XLS') ||
			props.s3ObjKey.toUpperCase().endsWith('.XLSX')
		);
		this.isOtherPreview = (
			props.s3ObjKey.toUpperCase().endsWith('.PDF') ||
			props.s3ObjKey.toUpperCase().endsWith('.TXT') ||
			props.s3ObjKey.toUpperCase().endsWith('.PNG')
		);
	}

	public contentLoaded() {
		/**
		 * Hide spinner when document finishes loading.
		 */
		(document.getElementById("spinner") as HTMLDivElement).style.display = "none";
	}

	private getPresignedUrl(): Observable<string> {
		/**
		 * Generate presigned S3 URL for a file via Portal API.
		 */
		let params = Object.assign({ "s3-obj-key": this.s3ObjKey });
		return this._http.getHttp('/jportal-file-link', params).pipe(map(response => {
			let fileUrl = "_blank";
			if (response.status === 200 && response.body.success === true) {
				fileUrl = response.body.payload;
			} else {
				console.error(response);
			}
			return fileUrl;
		}));
	}

	public openUrl() {
		/**
		 * Download file using presigned S3 URL from the Portal API.
		 */
		let url = document.getElementById(this.s3ObjKey) as HTMLAnchorElement;
		this.getPresignedUrl().subscribe(href => {
			url.href = href;
			url.click();
		});
	}

	public sanitize(url: string): SafeResourceUrl {
		/**
		 * Sanitize URL for file embedding.
		 */
		return this._sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}

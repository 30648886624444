import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { UserService } from '../../services/user.service';
import { UserRole } from '../../types/user-role.type';

@Component({
	imports: [SpinnerComponent],
	selector: 'jp-authenticate',
	standalone: true,
	templateUrl: './authenticate.component.html'
})
export class AuthenticateComponent {
	userInfo: UserRole[] | null = null;

	constructor( private _userService: UserService, private _router: Router ) {
		this._userService.userInfo.subscribe(result => {
			this.userInfo = result;
			if (this.userInfo) { this.checkAuthorization(); }
		});
	}

	checkAuthorization() {
		if (this.userInfo?.includes('Consumer')) {
			this._router.navigate(['/home']);
			return;
		} else {
			this._router.navigate(['/authenticate']);
			return;
		}
	}
}

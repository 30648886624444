// All file attributes corresponding to API

export type JPFile = {
    s3ObjKey: string; // primary key
    fileVersion: number | null;
    fileLatestVersion: number | null;
    fileDescription: string; // required for home view
    fileSize: string | null;
    fileVisibleIn: string[];
    fileVisibleTo: string[];
    fileCreatedBy: string | null;
    fileCreatedAt: string | null;
    lastUpdateAt: string; // required for home view
    lastUpdateBy: string | null;
}

export function sortJPFile(
    a: number | string | null,
    b: number | string | null
):
    number 
{
    /**
     * Custom sort function to be used on JPFile attributes.
     */
    if (b === null) { return -1; }
    if (a === null) { return 1; }
    return a.toString().localeCompare(b.toString());
}

<!--USWDS identifier-->

<footer>
	<div class="usa-identifier">
		<section class="usa-identifier__section usa-identifier__section--masthead" aria-label="Agency identifier">
			<div class="usa-identifier__container">
				<section class="usa-identifier__identity" aria-label="Agency description">
					<p class="usa-identifier__identity-domain">NLEAD.gov</p>
					<p class="usa-identifier__identity-disclaimer">
						An official website of the <a href="https://www.justice.gov/">U.S. Department of Justice</a>
					</p>
				</section>
			</div>
		</section>
		<nav class="usa-identifier__section usa-identifier__section--required-links" aria-label="Important links">
			<div class="usa-identifier__container">
				<ul class="usa-identifier__required-links-list">
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/about"
							>About DOJ</a
						>
					</li>
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/accessibility/accessibility-information"
							>Accessibility</a
						>
					</li>
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/oip"
							>FOIA</a
						>
					</li>
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/jmd/vulnerability-disclosure-policy"
							>Vulnerability Disclosure</a
						>
					</li>
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/jmd/eeo-program-status-report"
							>No FEAR Act Data</a
						>
					</li>
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/legalpolicies"
							>Legal Policies & Disclaimers</a
						>
					</li>
					<li class="usa-identifier__required-links-item">
						<a 
							class="usa-identifier__required-link usa-link"
							href="https://www.justice.gov/doj/privacy-policy"
							>Privacy Policy</a
						>
					</li>
				</ul>
			</div>
		</nav>
		<section class="usa-identifier__section usa-identifier__section--usagov"
			aria-label="U.S. government information and services">
			<div class="grid-container">
				<div class="grid-row">
				  <div class="tablet:grid-col-10">
					<div class="usa-identifier__usagov-description">
					  Looking for U.S. government information and services?&nbsp;
					</div>
					<a href="https://www.usa.gov/" class="usa-link">Visit USA.gov</a>
				  </div>
				  <div class="text-base-lighter tablet:grid-col-2">
					version 1.0.0
				  </div>
				</div>
			  </div>
		</section>
	</div>
	</footer>
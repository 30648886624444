export const environment = {
    production: false,
    local: true,
    apiEndpoint: 'https://library-api-uat.nlead.gov/prod',
    authConfig: {
        issuer: 'https://nlead-login-test.usdoj.gov',
        clientId: '0oamj1t9l70hMWg5n0j6',
        redirectUri: window.location.origin,
        responseType: 'code',
        postLogoutRedirectUri: 'https://nlead.gov/',
        showDebugInformation: true,
    },
};

export const columns = [
    "Suitability Professionals and Record-Owning Agency Representatives",
    "Data Managers and User Account Managers",
    "NLEAD Liaison Officers"
]

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, Subject, map, skipWhile } from 'rxjs';

import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard  {
	public loader$ = new Subject<boolean>();

	constructor( private _userService: UserService, private _router: Router ) { }
	
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree
	{
		this.loader$.next(true);
		return this._userService.userInfo.pipe(
			skipWhile(userInfo => !userInfo),
			map(userInfo => {
				this.loader$.next(false);
				if (state.url.split('/')[1] === 'home' && userInfo?.includes('Consumer')) {
					return true;
				}
				if (state.url.split('/')[1] === 'manage' && userInfo?.includes('Publisher')) {
					return true;
				}
				this._router.navigate(['/authenticate']);
				return false;
			})
		);
	}
}
